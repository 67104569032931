<template>
  <div>
    <h3 v-html="$t(`onboarding.registrationProcessOverview.${mainStage}.title`)"></h3>
    <p class="mt-5" v-html="$t(`onboarding.registrationProcessOverview.${mainStage}.desc`)"></p>
    <br>
    <h3>{{ $t('onboarding.registrationProcessOverview.title') }}</h3>
    <v-list>
      <div
        v-for="(item, index) in listMenu"
        :key="index"
      >
        <v-list-item
          v-if="item.key !== 'classification'
            || (showInvestmentClassification && mainStage !== 'investmentSurvey')"
        >
          <v-list-item-icon>
            <v-icon
              :color="style(item)"
            >
              {{item.icon}}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              :class="`${style(item)}--text`"
            >
              {{ $t(`onboarding.registrationProcessOverview.steps.${item.key}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

    </v-list>
    <v-btn
      color="primary"
      id="onboarding-next"
      x-large
      block
      class="black--text mb-10"
      @click="next"
    >
      {{ onBoardingCompleted ? $t('onboarding.navButtons.finish') : $t('onboarding.navButtons.next') }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RegistrationProcessOverview',
  data() {
    return {
      listMenu: [
        {
          icon: 'mdi-email-check-outline',
          key: 'account',
          complete: true,
        },
        {
          icon: 'mdi-account-outline',
          key: 'personal',
          complete: false,
        },
        {
          icon: 'mdi-lightbulb-outline',
          key: 'investmentSurvey',
          complete: false,
        },
        {
          icon: 'mdi-file-document-outline',
          key: 'classification',
          complete: false,
        },
        {
          icon: 'mdi-lock-outline',
          key: 'videoIdent',
          complete: false,
        },
        {
          icon: 'mdi-file-edit-outline',
          key: 'contractClosing',
          complete: false,
        },
        {
          icon: 'mdi-file-check-outline',
          key: 'investorApproval',
          complete: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
      'onBoardingCompleted',
      'investmentSurvey',
      'showInvestmentClassification',
    ]),
    menuIndex() {
      return this.listMenu.findIndex((item) => item.key === this.mainStage);
    },
  },
  created() {
    this.listMenu.forEach((item, key) => {
      key < this.menuIndex || this.onBoardingCompleted ? item.complete = true : item.complete = false;
    });
  },
  methods: {
    style(item) {
      return (item.complete) ? 'success' : (item.key === this.mainStage) ? 'primary' : 'defaultText';
    },
    next() {
      if (this.onBoardingCompleted) {
        this.$router.push({ name: 'Portfolio' });
      } else {
        this.$router.push({
          path: `/onboarding/${this.mainStage}/${this.subStage}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  background: transparent !important;
}
</style>
